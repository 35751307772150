import {useEffect, useState} from "react";
import {SearchForm} from "../../components/SearchForm";
import {Companies} from "../../components/Companies";
import {Table} from "../../components/Table";
import {FormFixed} from "../../components/FormFixed";
import {EndCall} from "../../components/EndCall";
import {ModalEndCall} from "../../components/ModalEndCall";
import axios from "axios";
import {Notification} from "../../components/Notification";


export const Main = () => {
    const queryString = window.location.toLocaleString();
    const urlParams = new URL(queryString).searchParams;
    const operator = urlParams.get('operator');
    const phone = urlParams.get('phone');
    const name = urlParams.get('name');
    const type = urlParams.get('type');
    const alert = urlParams.get('alert');
    const dialer_id = urlParams.get('dialer_id');
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isNotificationActive, setIsNotificationActive] = useState(false)
    const [notificationText, setNotificationText] = useState('')
    const [scripts, setScripts] = useState([])
    const [callStatus, setCallStatus] = useState('')
    const [recallStatus, setRecallStatus] = useState('')
    const [rejectionReason, setRejectionReason] = useState('')
    const [rejectionReasonList, setRejectionReasonList] = useState([])
    const [callStatusesList, setCallStatusesList] = useState([])
    const [regions, setRegions] = useState([])
    const [regionCurrent, setRegionCurrent] = useState('')
    const [vacancies, setVacancies] = useState([])
    const [vacancyCurrent, setVacancyCurrent] = useState('')
    const [vacanciesList, setVacanciesList] = useState([])
    const [companies, setCompanies] = useState([])
    const [companiesCurrent, setCompaniesCurrent] = useState([])
    const [citizenshipList, setCitizenshipList] = useState([])
    const [userInfo, setUserInfo] = useState({
        phone: phone,
        name: '',
        birthDate: '',
        citizenship: '',
        vacancyIds: [],
        comment: '',
    })


    // temporary
    const token = 'ea92c1c9-86f0-4ecc-9e2a-115f1499290a'
    const headers = {Authorization: token};


    useEffect(() => {

        const queryLocStr = window.location.toLocaleString();
        const urlsParams = new URL(queryLocStr).searchParams;
        const phone = urlsParams.get('phone');
        const operator = urlsParams.get('operator');
        const msisdn = urlsParams.get('msisdn');
        console.log(phone,phone.includes('edu'))
        const pr = phone.replace(/\d+/g, '')
        if (pr) {
            switch (pr) {
                case 'uhome':
                    // eslint-disable-next-line no-restricted-globals
                    location.replace(`https://leadslabpixels.net/forms/estate.html?forms=uhome_samolet2&operator=${operator}&msisdn=${phone.match(/\d+/g).map(Number)}&dialer_id=137`)
                    break;
                case 'edu':
                    console.log('EDU')
                    // eslint-disable-next-line no-restricted-globals
                    location.replace(`https://leadslabpixels.net/forms/edu.html?forms=skysmart&operator=${operator}&msisdn=${phone.match(/\d+/g).map(Number)}&dialer_id=146`)
                    break;
                case 'utra':
                    // eslint-disable-next-line no-restricted-globals
                    location.replace(`https://leadslabpixels.net/forms/travel.html?forms=travels&operator=${operator}&msisdn=${phone.match(/\d+/g).map(Number)}&dialer_id=144`)
                    break;
                default:
                    console.log('loaded')
            }
        }



        // script
        axios.get('https://uhead.caltat.com/api/get-script', {headers}).then((res) => {
            setScripts(res.data)
        });
        // regions
        axios.get('https://uhead.caltat.com/api/get-cities', {headers}).then((res) => {
            setRegions(res.data)
            setRegionCurrent(res.data[0])
        });
        // citizenship
        axios.get('https://uhead.caltat.com/api/get-citizenships', {headers}).then((res) => {
            setCitizenshipList(res.data)
            setUserInfo(prev => ({...prev, citizenship: res.data[0]}))
        });
        // call statuses
        axios.get('https://uhead.caltat.com/api/get-call-statuses', {headers}).then((res) => {
            setCallStatusesList(res.data)
            setCallStatus(res.data[0])
        });
        // rejection reasons
        axios.get('https://uhead.caltat.com/api/get-decline-statuses', {headers}).then((res) => {
            setRejectionReasonList(res.data)
            setRejectionReason(res.data[0])
        });

        setIsFirstRender(false)
    }, [])

    useEffect(() => {
        if (!isFirstRender && regions.includes(regionCurrent)) {
            axios.get(`https://uhead.caltat.com/api/get-vacancy-names?city=${regionCurrent}`, {headers}).then((res) => {
                setVacancies(res.data)
                setVacancyCurrent(res.data[0])
                setCompaniesCurrent([])
            });
        }
    }, [regionCurrent])

    useEffect(() => {
        if (companies.length > 0 && !isFirstRender && regions.includes(regionCurrent)) {
            axios.get(`https://uhead.caltat.com/api/get-vacancies?city=${regionCurrent}&vacancy_name=${vacancyCurrent}&companies=${companiesCurrent.length > 0 ? companiesCurrent.join(',') : companies.join(',')}`, {headers}).then((res) => {
                setVacanciesList(res.data)
            });
        }
    }, [companiesCurrent, companies, vacancyCurrent])


    const sendUserInfo = () => {
        axios.post(`https://uhead.caltat.com/api/write-vacancy-requests?phone=${phone}&name=${userInfo.name}&birth_date=${userInfo.birthDate}&citizenship=${userInfo.citizenship}&vacancy_ids=${userInfo.vacancyIds.join(',')}${userInfo.comment ? `&comment=${userInfo.comment}` : ''}`, null, {headers}).then((res) => {
            setUserInfo(prev => ({...prev, vacancyIds: []}))
            setIsNotificationActive(true)
            setNotificationText('Заявка отправлена')
            setTimeout(() => {
                setIsNotificationActive(false)
                setNotificationText('')
            }, 3000)

        });
    }


    const sendCallStatus = () => {
        axios.post(`https://uhead.caltat.com/api/write-call?phone=${phone}&form_type=${type}&operator=${operator}&status=${callStatus}${callStatus === 'Отказ' ? `&decline_status=${rejectionReason}` : ''}`, '', {headers}).then((res) => {
            if (callStatus === 'Срыв(перезвон)' || callStatus === 'Перезвон') {
                axios.post(`https://uhead.caltat.com/api/add-recall-task?phone=${phone}&recall_date=${recallStatus}&dialer_id=${dialer_id}`, '', {headers}).then((res) => {

                })
            }
            setIsNotificationActive(true)
            setNotificationText('Статус отправлен')
            setTimeout(() => {
                setIsNotificationActive(false)
                setNotificationText('')
            }, 3000)
        });


    }

    useEffect(() => {
            if (vacancyCurrent && regionCurrent && !isFirstRender && regions.includes(regionCurrent)) {
                axios.get(`https://uhead.caltat.com/api/get-companies?city=${regionCurrent}&vacancy_name=${vacancyCurrent}`, {headers}).then((res) => {
                    setCompanies(res.data)
                    setCompaniesCurrent([])

                });
            }
        },
        [vacancyCurrent, regionCurrent]
    )


    // ALERT

    const [buttonClicked, setButtonClicked] = useState(false);

    function sendReport(alert) {
        const project = urlParams.get('forms') ? urlParams.get('forms') : urlParams.get('proj');
        fetch(`https://uhead.caltat.com/api/log?phone=${phone}&operator=${operator}&project=${project}&action='проебался ${alert === 'time1' ? 'по времени (ннд)' : alert === 'time2' ? 'по времени' : 'при закрытии'}'`, {
            method: "POST",
            headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw new Error('Request failed');
                }
            })
            .catch(function (error) {
                console.error(error);
            });
        if (alert) {
            return 'abc'
        }
    }

    useEffect(() => {
        if (alert === 'true') {
            let timeout = setTimeout(function () {
                // Send a request to the server
                sendReport('time1')

            }, 10 * 60 * 1000);

            function handleMouseMove() {
                clearTimeout(timeout);
                if (!buttonClicked) {
                    timeout = setTimeout(function () {
                            // Send a request to the server
                            sendReport('time2')

                        }
                        , 10 * 60 * 1000);
                }
            }

            document.addEventListener('mousemove', handleMouseMove);

            const sendRequestButton = document.getElementById('sub');
            sendRequestButton.addEventListener('click', function () {
                setButtonClicked(true);
                clearTimeout(timeout);
            });

            function handleBeforeUnload() {
                return !buttonClicked ? sendReport(alert) : null;
            }

            window.onbeforeunload = handleBeforeUnload;

            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
                sendRequestButton.removeEventListener('click', function () {
                    setButtonClicked(true);
                    clearTimeout(timeout);
                });
                window.onbeforeunload = null;
            };
        } else {
            console.log('hello');
        }
    }, [buttonClicked]);

    return (
        <div>
            <div style={{marginBottom: 30}}>
                {
                    queryString.includes('type=response') && <div style={{marginBottom: 10, color: 'red'}}>
                        <div style={{marginBottom: 10}}>
                            <div style={{marginBottom: 10, color: 'green'}}>Пользователь откликнулся на вакансию в  приложении (предложить еще вакансии):</div>
                            <div style={{marginBottom: 10, color: 'green'}}>{name}</div>
                            <div style={{marginBottom: 10}}>Добрый день,</div>
                            <div style={{marginBottom: 10}}> Меня зовут {operator}, из компании uHead!</div>
                            <div style={{marginBottom: 10}}> Вижу, что вы откликнулись на вакансию в нашем приложении, давайте подберем еще подходящие вакансии!
                            </div>
                        </div>
                    </div>
                }
                {
                    queryString.includes('type=reg') && <div style={{marginBottom: 10, color: 'red'}}>
                        <div style={{marginBottom: 10}}>
                            <div style={{
                                marginBottom: 10,
                                color: 'green'
                            }}>Пользователь зарегистрировался в приложении:</div>
                            <div style={{marginBottom: 10}}>Добрый день,</div>
                            <div style={{marginBottom: 10}}> Меня зовут {operator}, из компании uHead!</div>
                            <div style={{marginBottom: 10}}> Вижу, что вы зарегистрировались в нашем приложении, помогу вам
                                определиться с интересными вакансиями!
                            </div>
                            <div style={{marginBottom: 10}}> Подскажите в каком городе ищите работу?</div>
                            <div style={{marginBottom: 10}}> Какие позиции рассматриваете?</div>
                        </div>
                    </div>
                }
                {!queryString.includes('type=response') && !queryString.includes('type=reg') && scripts &&
                    scripts.map((script, key) => (
                        <div key={key} style={{marginBottom: 10}}>{script}</div>
                    ))}

            </div>
            <SearchForm {...{
                regions,
                setRegionCurrent,
                vacancies,
                setVacancyCurrent,
                regionCurrent,
                vacancyCurrent
            }}/>
            <Companies {...{
                companies,
                companiesCurrent,
                setCompaniesCurrent
            }}/>

            <Table
                {...{
                    vacanciesList,
                    userInfo,
                    setUserInfo
                }}/>
            <EndCall {...{
                isModalOpen,
                setIsModalOpen,
                userInfo,
                setButtonClicked
            }}/>
            <ModalEndCall
                {...{
                    isModalOpen,
                    callStatus,
                    setIsModalOpen,
                    callStatusesList,
                    setCallStatus,
                    sendCallStatus,
                    rejectionReasonList,
                    rejectionReason,
                    setRejectionReason,
                    recallStatus,
                    setRecallStatus,
                    setButtonClicked

                }}
            />
            <FormFixed {...{
                userInfo,
                setUserInfo,
                citizenshipList,
                sendUserInfo,
                buttonClicked,
                setButtonClicked
            }}/>
            <Notification {...{
                isNotificationActive,
                notificationText
            }}/>

        </div>
    )
}






