import './style.css'
export const FormFixed = (props) => {
    const {
        userInfo,
        setUserInfo,
        citizenshipList,
        sendUserInfo,
        setButtonClicked
    } = props
    return (
        <form id="main-block"
             style={{
                 padding: 20,
                 backgroundColor: '#ffe29e',
                 position: 'fixed',
                 bottom: 0,
                 left: 0,
                 width: '100%',
                 boxSizing: 'border-box'
             }}>
            <div className="flex main-block-cntnr" style={{display: "flex",}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <label htmlFor="name">ФИО:</label>
                    <input type="text"
                           name="name"
                           id="name"
                           required={true}
                           value={userInfo.name}
                           onChange={(e) => setUserInfo(prev => ({...prev, name: e.target.value}))}/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <label htmlFor="birth_date">Дата рождения:</label>
                    <input
                        id="birth_date"
                        type="date"
                        min='1899-01-01'
                        max='2020-01-01'
                        required
                        value={userInfo.birthDate}
                        onChange={(e) => setUserInfo(prev => ({...prev, birthDate: e.target.value}))}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <label htmlFor="citizenship">Гражданство:</label>
                    <select name="citizenship" id="citizenship" className="select"  onChange={(e) => setUserInfo(prev => ({...prev, citizenship: e.target.value}))}>
                        {citizenshipList &&
                            citizenshipList.map((citizenship, key) => (
                                <option value={citizenship} key={key}>{citizenship}</option>

                            ))}
                    </select>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <label htmlFor="comment">Комментарий:</label>
                    <input name="comment" id="comment" cols="40" rows="3"
                           value={userInfo.comment}
                           onChange={(e) => setUserInfo(prev => ({...prev, comment: e.target.value}))
                    }
                    ></input>
                </div>
                <div style={{minWidth: 150, position: 'relative', display: 'flex', alignItems:'flex-end'}}>

                    <button disabled={userInfo.vacancyIds.length < 1 || !userInfo.name || !userInfo.birthDate} type="submit" value="Отправить" onClick={(e)=>{e.preventDefault();sendUserInfo()}}>Отправить данные</button>
                    <div style={{position:'absolute', top:45}}>Выбрано: {userInfo.vacancyIds.length}</div>
                </div>
            </div>
        </form>
    )
}