export const Notification = (props) =>{
    const {notificationText,isNotificationActive} = props
    return (
        <div style={{
            display: isNotificationActive ? 'block' : 'none',
            position: 'fixed',
            left: 19,
            bottom: '10vh',
            padding: 20,
            background: '#ffb900'

        }}>
            {notificationText}
        </div>
    )
}