export const Companies = (props) => {
    const {
        companies,
        setCompaniesCurrent,
        companiesCurrent
    } = props
    return (
        <div style={{display: 'flex', marginBottom: 20}}>
            {companies &&
                companies.map((item, key) => (
                    <div key={key}
                         style={{marginRight: 20, background: companiesCurrent.includes(item) ? 'green' : 'transparent', padding: 5, border: '1px solid black'}}
                         onClick={() => companiesCurrent.includes(item) ? setCompaniesCurrent((prevState) => prevState.filter((prevItem) => prevItem !== item)) : setCompaniesCurrent([...companiesCurrent, item])}>{item}</div>
                ))}

        </div>
    )
}