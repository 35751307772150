import './style.css'
export const EndCall = (props) =>{
    const {
        setIsModalOpen,
        setButtonClicked
    } = props

    return (
        <div className={'endCall'} style={{
            position: 'fixed',
            right: 0,
            padding: '20px 20px',
            background: 'rgb(255, 226, 158)',
            border: '1px solid black'
        }}>
            <button onClick={()=>{setIsModalOpen(true);setButtonClicked(true)}} id={'sub'} >
                Завершить звонок
            </button>
        </div>
    )
}