import './style.css'
import ReactMarkdown from "react-markdown";
export const Table = (props) =>{
    const {
        vacanciesList,
        userInfo,
        setUserInfo
    } = props
    return (
        <table className="customTable">
            <thead>
            <tr>
                <th></th>
                <th>Компания</th>
                <th>Метро/Район</th>
                <th>Описание</th>
            </tr>
            </thead>
            <tbody>
            {vacanciesList &&
                vacanciesList.map((vacancy, key) => (
                    <tr key={key}>
                        <td><input type="checkbox" onChange={()=>{setUserInfo(prev => ({...prev, vacancyIds: userInfo.vacancyIds.includes(vacancy.id) ? userInfo.vacancyIds.filter(i => i !== vacancy.id) : [ ...userInfo.vacancyIds, vacancy.id ]}))}} checked={userInfo.vacancyIds.includes(vacancy.id)}/></td>
                        <td>{vacancy.company_name}</td>
                        <td>{vacancy.district}</td>
                        <td><ReactMarkdown>{vacancy.description}</ReactMarkdown></td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}