export const SearchForm = (props) => {
    const {
        regions,
        regionCurrent,
        setRegionCurrent,
        vacancies,
        setVacancyCurrent,
        vacancyCurrent
    } = props
    return (
        <div style={{marginBottom: 30}}>
            <form style={{display: 'flex'}}>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                    <label htmlFor="region">Регион</label>
                    <input onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} type="text" value={regionCurrent} list="region" onChange={(e) => {e.preventDefault();setRegionCurrent(e.target.value)} }/>
                    <datalist id="region" name="regions">
                        {regions &&
                            regions.map((item, key) => (
                                <option value={item} key={key}/>
                            ))}
                    </datalist>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', marginRight: 10}}>
                    <label htmlFor="vacancy">Вакансия</label>
                    <select id="vacancy" name="vacancy" value={vacancyCurrent} onChange={(e) => setVacancyCurrent(e.target.value)}>
                        {vacancies &&
                            vacancies.map((item, key) => (
                                <option value={item} key={key}
                                        onClick={() => setVacancyCurrent(item)}>{item}</option>
                            ))}
                    </select>
                </div>


                {/*<button onClick={(e) => {e.preventDefault();onSubmit()}}>Найти</button>*/}
            </form>
        </div>
    )
}