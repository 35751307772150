import logo from './logo.svg';
import './App.css';
import {Main} from "./pages/Main";

function App() {
  return (
    <div className="App">
    <Main/>
    </div>

  );
}

export default App;
