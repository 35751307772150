export const ModalEndCall = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        callStatusesList,
        callStatus,
        setCallStatus,
        sendCallStatus,
        rejectionReasonList,
        rejectionReason,
        setRejectionReason,
        recallStatus,
        setRecallStatus,

    } = props
    return (
        <div style={{
            display: isModalOpen ? 'block' : 'none',
            background: 'white',
            width: 500,
            height: 200,
            position: 'fixed',
            right: 0,
            bottom: '14vh',
            border: '1px solid black',
            padding: 10
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '-webkit-fill-available',
                marginRight: 10,

            }}>
                <label htmlFor="callStatus" style={{marginBottom: 10}}>Статус звонка:</label>
                <select name="callStatus" id="callStatus" className="select" style={{marginBottom: 10}}
                        onChange={(e) => setCallStatus(e.target.value)}>
                    {callStatusesList &&
                        callStatusesList.map((callStatus, key) => (
                            <option value={callStatus} key={key}>{callStatus}</option>

                        ))}
                </select>
                {callStatus === 'Отказ' &&
                    <>
                        <label htmlFor="reason" style={{marginBottom: 10}}>Причина отказа:</label>
                        <select name="reason" id="reason" className="reason" style={{marginBottom: 10}}
                                onChange={(e) => setRejectionReason(e.target.value)}>
                            {rejectionReasonList &&
                                rejectionReasonList.map((status, key) => (
                                    <option value={status} key={key}>{status}</option>

                                ))}
                        </select>
                    </>
                }
                {
                    (callStatus === 'Срыв(перезвон)' || callStatus === 'Перезвон') && <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '-webkit-fill-available',
                        marginBottom: 20
                    }}>
                        <label htmlFor="comment">Выберите дату перезвона:</label>
                        <input
                            type="datetime-local"
                            value={recallStatus}
                            onChange={(e) => setRecallStatus(e.target.value)
                            }
                        />
                    </div>
                }
            </div>
            <button onClick={() => {
                setIsModalOpen(false);
                sendCallStatus()

            }}>
                Отправить статус
            </button>

        </div>
    )
}